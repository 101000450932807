@import '../styles/_reset';
@import '../styles/_vars';

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;

  &:active {
    color: $secondary
  }
}

img {
  max-width: 100%;
}

h2 {
  font-family: 'Jost', sans-serif;
  font-size: clamp(1rem, 2vw + 1rem, 1.2rem);
  font-weight: 900;
}

h3 {
  font-family: 'Jost', sans-serif;
  font-size: 22px;
  font-weight: 200;
}

h4 {
  font-family: 'Jost', sans-serif;
  font-size: clamp(0.9rem, 2vw + 0.5rem, 1.2rem);
  font-weight: 700;
}

h5 {
  font-family: 'Jost', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

h6 {
  font-family: 'Jost', sans-serif;
  font-size: clamp(2rem, 4vw + 1rem, 4rem);
  font-weight: 200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editor {
  border: 1px solid rgb(231, 231, 231);
  border-radius: 3px;
  padding: 0.5rem;

  &:hover {
    border: 1px solid black;
  }
}

.public-DraftStyleDefault-ul {
  list-style-type: '-';
}

.atelierDetails {
  &-title {
    margin-bottom: 1rem;
  }

}

.grid {
  &-item {
    &--title {
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
@import '../../styles/_vars';

.homeEventsList {
  
  &-card {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    background-color: #eee;
    border-radius: 5em;
    padding: 1rem 2rem;
    gap: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 40em) {
  .homeEventsList {
    &-card {
      padding: 1rem;
    }
}
}
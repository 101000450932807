@import '../../styles/vars';

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-title {
    color: $main;
    padding-bottom: 1rem;
  }
}
.dbStatus {
  padding: 1rem;
  background: rgb(240, 239, 239);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  position: fixed;
  top: 4rem;
  right: -15rem;
  transition: all 300ms ease-in-out;

  &-chip {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}

// POSITION
.open {
  right: 0rem;
}

.close {
  right: -16rem
}

// CHIP
.connected {
  background-color: rgb(0, 255, 0);
}

.unconnected {
  background-color: red;
}

@import '../../../../styles/vars';

.white {
  color: red;
}

.dangerous-html {
  font-size: 0.9rem;
  line-height: 1.2rem;

  // Type
  & > p {
    margin-bottom: 0.5rem;
    line-height: 1.3rem;
  }

  & > strong {
    font-weight: $bold;
  }

  & > em {
    font-style: italic;
  }

  & > del {
    text-decoration: line-through;
  }

  & > ins {
    text-decoration: underline;
  }

  // Elements
  & > blockquote {
    position: relative;
    padding: 2rem 3rem;
    margin: 1rem 0.5rem;
    background-color: rgb(240, 240, 240);
    width: fit-content;

    &::before {
      content: '«';
      font-size: 4em;
      margin-left: -2.5rem;
      margin-top: -1.5rem;
      transform: rotate(45deg);
    }

    &::after {
      content: '»';
      bottom: 0;
      right: 0.2rem;
      margin-top: 1rem;
      transform: rotate(45deg);
    }
  }

  & > blockquote::before,
  & > blockquote::after {
    line-height: 0.8;
    position: absolute;
    font-size: 4em;
    // display: inline-block;
    color: rgb(255, 255, 255);
    font-size: 4em;
    top: 0.2em;
  }

  & > ul {
    margin: 1rem 0;
    padding-left: 0.5rem;

    & > li {
      list-style-type: disc;
      margin: 0.5rem 2rem;
    }
  }

  & > ol {
    margin: 1rem 0;
    padding-left: 1rem;

    & > li {
      list-style-type: decimal;
      margin: 0.5rem 1rem;
    }
  }
}

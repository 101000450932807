@import '../../styles/_vars';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background: $main;
  width: 100%;
  padding: 1rem 0;
  gap: 1rem;

  &-link {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}


.eventCard {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &-grid {
    margin: 1rem 0;

    &--item {
      display: flex;
      align-items: center;
    }
  }
  
  &-shadowbox {
    overflow-y: hidden; 
  }
  
  &-arrow {
    width: 120%;
    margin-left: -10%;
    text-align: center;
    background: #ffffff00;
    box-shadow: 0 -6.5rem 3rem -7rem rgba(0,0,0,0.2) inset;
    -webkit-box-shadow: 0 -6.5rem 3rem -7rem rgba(0,0,0,0.2) inset;
    -moz-box-shadow: 0 -6.5rem 3rem -7rem rgba(0,0,0,0.2) inset; 
  }
}
